<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="auto" class="text-right">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <sync-button
                  :resource="resource"
                  :repository="TransferReceiptRepository"
                  :module="$t('breadcrumb.transferReceipt')"
                  @success="onSyncSuccess"
                />
              </b-col>
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          @sort-changed="sortChanged"
          :key="`table-${key}`"
          :visible-columns="visibleColumns"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :resource="resource"
          :route="route"
          :viewable="true"
          :updatable="false"
        >
          <template #cell(status)="data">
            <span :class="data.item.status">
              {{ data.item.status }}
            </span>
          </template>
        </n-table>
        <n-pagination
          :key="`page-${query.page}`"
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BFormGroup, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NColumnVisibility from "@/components/NColumnVisibility";
import SyncButton from "@/components/sync/SyncButton.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const TransferReceiptRepository = Repository.get("transferReceipt");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BBadge,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NAsyncSingleSelect,
    NColumnVisibility,
    SyncButton,

    vSelect,
  },
  watch: {
    perPage() {
      this.changePage();
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.transferOrder;
      },
      set(value) {
        this.$store.commit("column/SET_TRANSFER_ORDER_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    userData: function () {
      return this.$store.state.profile.email
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        transferDate: this.$route.query.transferDate || [],
        no: this.$route.query.no || null,
        status: this.$route.query.status || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        transferDate: this.$route.query.transferDate || [],
        no: this.$route.query.no || null,
        status: this.$route.query.status || [],
      },
      TransferReceiptRepository,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.search();
    },
    onSyncSuccess() {
      this.query.page = 1;
      this.getData(true);
    },
    getData(isSync = false) {
      this.loading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      TransferReceiptRepository.index({
        ...this.query,
        searchFields: this.searchFields,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
            if (isSync) {
              this.$hideSyncing();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                autoHideDelay: 2000,
                props: {
                  title: this.$t("alert.syncSuccess"),
                  iconType: "line-awesome",
                  icon: "las la-cloud-download-alt",
                  variant: "light-success",
                  textVariant: "success",
                },
              });
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "agent-transfer-receipt";
    const route = "transfer-receipt";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
